import * as React from 'react';
import Title from '../components/title';
import { Root } from '../styles';
// markup
const NotFoundPage = () => {
  return (
    <>
      {' '}
      <Title title={'Not Found | Juicer'}></Title>
      <Root>Ops, Not Found.</Root>
    </>
  );
};

export default NotFoundPage;
